<template>
  <div class="detail">
    <Header url="search" @upFile="upFilefun"></Header>
    <div class="catebg">
      <img src="../assets/img/allSpc.jpg" />
      <div class="carttitle">
            <div class="search ">
              <div style="position:relative;">
                <input type="text" placeholder="" />
                <div class="searchbtn bgcolor"><img src="../assets/img/search.png" alt=""></div>
              </div>
            </div>
      </div>

    </div>
    <div class="goodslist">
       <div class="item clearfix" v-for="(item,index) in goodslist" :key="index" @click="showbox(index)">
          <div class="goodsimg">
            <img src="../assets/img/1.png" alt="">
            <div class="imgtitle line1">西红柿炒鸡蛋</div>
          </div>

           <span>标签:</span><input type="text" class="itinput"  value="特价">
           <span>菜名:</span><input type="text"  class="itinput"  value="西红柿炒鸡蛋">
           <span>价格:</span><input type="text" class="itinput"  value="20">
           <span class="gocart bgcolor">加入菜谱</span>

       </div>
      <div class="clearfix"></div>
    </div>

    <Footer />

  </div>
</template>
<style>
.detail .catebg{position: relative;}
.detail .search{position: absolute;left: 50%;top: 50%;z-index: 10;transform: translate(-50%, -50%);overflow: hidden;border-radius: 4px}
.detail .search input{    position: relative; padding-left: 16px;        width: 570px;        height: 48px;        line-height: 48px;
  background-color: #fff;        z-index: 10;        font-size: 14px;        border: 1px solid #e0dfdf;        outline: 0;}
.detail .search .searchbtn{position:absolute;top: 0;right: 0;z-index: 11;    cursor: pointer;}
.detail .search .searchbtn img{width: 30px;    height: 30px;    padding: 9px 20px;}
.detail  .goodslist{width: 1280px;overflow: hidden;margin: auto;}
.detail  .goodslist .item{position: relative;padding: 10px; border-bottom: 1px solid #ccc;line-height: 126px;}
.detail  .goodslist .item .goodsimg{position: relative;width: 126px;  height: 126px; float: left;cursor: pointer;}
.detail  .goodslist .item .imgtitle{position:absolute;bottom: 0;width: 100%;height: 40px;line-height: 40px;background: #000000ab;color: #fff;text-align: center;}
.detail  .goodslist .item span{margin-left: 20px;}
.detail  .goodslist .item .itinput{margin: 10px;padding:6px 10px;width: 150px;border: 1px solid #ccc;outline: 0}
.detail  .gocart{padding: 8px;  color: #fff;cursor: pointer}

</style>
<script>
import Header from '@/components/header'
import Footer from '@/components/footer'


export default {
  name: 'Cart',
  components:{Header,Footer},
  data() {
    return {
      id:0,
      goodslist:[1,2],
      box:false,
      msg:{'show':false}
    }
  },
  watch: {

  } ,
  mounted(){ },
  methods: {
    upFilefun(e){
       this.msg.show = e
    },
    showbox(id){
      console.log(id);
       this.box = true;
    },
    changeimg(id){
      console.log(id);
      this.box = false;
    },
    close(){
      this.box = false;
    }
  }
}
</script>
